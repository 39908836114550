var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass: "d-flex justify-space-between primary--text",
            staticStyle: { width: "100%" }
          },
          [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Table access")
            ]),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bold",
                attrs: { text: "", color: "primary" },
                on: {
                  click: function($event) {
                    return _vm.generateToken()
                  }
                }
              },
              [_vm._v("Generate New Token")]
            )
          ],
          1
        )
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c("v-col", { attrs: { cols: "2" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v("mdi-key-variant")
                    ]),
                    _vm._v(" Current Token ")
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-text-field", {
                      staticClass: "input-group--focused mr-1",
                      attrs: {
                        "append-icon": _vm.show
                          ? "visibility"
                          : "visibility_off",
                        type: _vm.show ? "text" : "password",
                        value: _vm.snowflakeTokens.access_token,
                        loading: _vm.loading,
                        disabled: _vm.loading
                      },
                      on: {
                        "click:append": function($event) {
                          _vm.show = !_vm.show
                        }
                      }
                    }),
                    _c("CopyToClipboard", {
                      attrs: {
                        textToCopy: _vm.snowflakeTokens.access_token,
                        buttonClass: "caption font-weight-bold",
                        buttonColor: "primary",
                        isTextButton: true,
                        buttonName: "copy to clipboard",
                        iconButton: true,
                        disabled: _vm.loading,
                        isSmall: false
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }